<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- subscriptions -->
    <b-tab
      active
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Subscriptions</span>
      </template>

      <account-channel-settings />
    </b-tab>
    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Payment Methods</span>
      </template>

      <account-setting-information />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountChannelSettings from './AccountChannelSettings.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingInformation,
    AccountChannelSettings,
  },
  data() {
    return {
      creatorStatus: '',
      userData: '',
      monetized: false,
      options: {},
    }
  },
  // eslint-disable-next-line no-empty-function
  async mounted() {
  },
  async beforeCreate() {
    this.userData = await window.brime.getUser()
  },
}
</script>
