<template>
  <div><h2>Click the button below to Manage payment methods</h2><br><b-button
    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    size="lg"
    variant="primary"
    style="line-height:14px;"
    class="float-middle"
    @click="openCheckout()"
  >Manage Payment Methods
  </b-button></div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'
// eslint-disable-next-line dot-notation
axios.defaults.headers.post['Authorization'] = `Bearer ${window.brime.getAccessToken()}`
export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      selected: null,
      billingInfo: {},
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  async mounted() {
    this.cbInstance = window.Chargebee.init({ site: 'brime' })
    this.billingInfo = await window.brime.getBillingInfo()
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    openCheckout() {
      this.cbInstance.openCheckout({
        hostedPage: () => {
          const messageBody = {
          }
          return axios.post(`${window.brime.apiBase}/internal/billing/generate_update_payment_method_url?client_id=${window.brime.clientId}`, messageBody).then(response => response.data.data.hosted_page)
        },
        loaded() {
        },
        close() {
        },
        // eslint-disable-next-line no-unused-vars
        success(hostedPageId) {
        },
        // eslint-disable-next-line no-unused-vars
        step(value) {
        },
      })
    },
    repeateAgain() {
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
